exports.components = {
  "component---src-pages-jsx": () => import("./../../../src/pages/[...].jsx" /* webpackChunkName: "component---src-pages-jsx" */),
  "component---src-pages-white-label-light-components-landing-page-components-call-to-action-section-tsx": () => import("./../../../src/pages/white-label-light/components/landing-page/components/CallToActionSection.tsx" /* webpackChunkName: "component---src-pages-white-label-light-components-landing-page-components-call-to-action-section-tsx" */),
  "component---src-pages-white-label-light-components-landing-page-components-feature-section-tsx": () => import("./../../../src/pages/white-label-light/components/landing-page/components/FeatureSection.tsx" /* webpackChunkName: "component---src-pages-white-label-light-components-landing-page-components-feature-section-tsx" */),
  "component---src-pages-white-label-light-components-landing-page-components-footer-tsx": () => import("./../../../src/pages/white-label-light/components/landing-page/components/Footer.tsx" /* webpackChunkName: "component---src-pages-white-label-light-components-landing-page-components-footer-tsx" */),
  "component---src-pages-white-label-light-components-landing-page-components-hero-section-tsx": () => import("./../../../src/pages/white-label-light/components/landing-page/components/HeroSection.tsx" /* webpackChunkName: "component---src-pages-white-label-light-components-landing-page-components-hero-section-tsx" */),
  "component---src-pages-white-label-light-components-landing-page-components-navbar-tsx": () => import("./../../../src/pages/white-label-light/components/landing-page/components/Navbar.tsx" /* webpackChunkName: "component---src-pages-white-label-light-components-landing-page-components-navbar-tsx" */),
  "component---src-pages-white-label-light-components-landing-page-components-product-showcase-tsx": () => import("./../../../src/pages/white-label-light/components/landing-page/components/ProductShowcase.tsx" /* webpackChunkName: "component---src-pages-white-label-light-components-landing-page-components-product-showcase-tsx" */),
  "component---src-pages-white-label-light-components-landing-page-components-statistics-section-tsx": () => import("./../../../src/pages/white-label-light/components/landing-page/components/StatisticsSection.tsx" /* webpackChunkName: "component---src-pages-white-label-light-components-landing-page-components-statistics-section-tsx" */),
  "component---src-pages-white-label-light-components-landing-page-components-trusted-by-section-tsx": () => import("./../../../src/pages/white-label-light/components/landing-page/components/TrustedBySection.tsx" /* webpackChunkName: "component---src-pages-white-label-light-components-landing-page-components-trusted-by-section-tsx" */),
  "component---src-pages-white-label-light-components-landing-page-index-tsx": () => import("./../../../src/pages/white-label-light/components/landing-page/index.tsx" /* webpackChunkName: "component---src-pages-white-label-light-components-landing-page-index-tsx" */),
  "component---src-pages-white-label-light-router-tsx": () => import("./../../../src/pages/white-label-light/router.tsx" /* webpackChunkName: "component---src-pages-white-label-light-router-tsx" */)
}

