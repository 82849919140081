import { useDescope } from '@descope/react-sdk';
import { useGetCurrentUserQuery } from '@src/apiFetch/authApi';
import useSessionManager from '@src/hooks/useSessionManager';

const useUserSession = () => {
    const { logout: logoutDescope, isSessionLoading, sessionToken } = useSessionManager();
    const { getSessionToken } = useDescope();

    // Load /me before anything else to prevent multiple endpoints from trying to provision the user on-demand
    const { data: user, isLoading: isUserLoading, refetch } = useGetCurrentUserQuery({
        enabled: !isSessionLoading && !!sessionToken,
    });

    const logout = () => {
        sessionStorage.removeItem('adminMode');
        logoutDescope();
    };

    const isRetrievingUser = isSessionLoading || isUserLoading;
    return {
        isRetrievingUser,
        logout,
        accessToken: sessionToken,
        getAccessToken: getSessionToken,
        user: isRetrievingUser ? null : user,
        refetch,
    };
};

export default useUserSession;
