import { useMutation, useQuery } from 'react-query';
import type { AxiosResponse } from 'axios';
import type { User } from '@src/types/user';
import { BACKEND_API } from './axiosConfig';

type OAuthTokenResponse = {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
};

const useRedeemAuthorizationCodeMutation = (mutationOpts = undefined) => {
    return useMutation(({ code }: { code: string }) => {
        const redirectUri = `${window.location.origin}/login-callback`;
        return BACKEND_API.post<any, AxiosResponse<OAuthTokenResponse>>(
            'auth/redeem_authorization_code',
            {
                redirect_uri: redirectUri,
                code,
            },
            {
                skipAuth: true,
            },
        );
    }, mutationOpts);
};

export const useGetCurrentUserQuery = (queryOpts = {}) => {
    return useQuery(['getCurrentUser'], async () => {
        return (await BACKEND_API.get<any, AxiosResponse<User>>(
            '/me',
        )).data;
    }, {
        refetchOnMount: false, // Do not refetch unless explicitly invalidated
        ...queryOpts,
    });
};

export const useAlloyStatus = (queryOpts = undefined) => {
    return useQuery(['getAlloyStatus'], async () => {
        return (await BACKEND_API.get<any, AxiosResponse<User>>(
            '/alloy/update-status',
        )).data;
    }, queryOpts);
};

export default useRedeemAuthorizationCodeMutation;
