/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { create } from 'zustand';
import queryString from 'query-string';
import { navigate } from '@gatsbyjs/reach-router';
import guardianQueryClient from '@src/apiFetch/guardianQueryClient';
import getConfig from '@src/util/getConfig';

const withStorageDOMEvents = (store) => {
    const storageEventCallback = (e) => {
        if (e.key === store.persist.getOptions().name && e.newValue) {
            console.log('Rehydrating!');
            store.persist.rehydrate();
        }
    };

    window.addEventListener('storage', storageEventCallback);

    return () => {
        window.removeEventListener('storage', storageEventCallback);
    };
};

export const useCobrowseStore = create(
    (set) => ({
        initialized: false,
        session: null,
        cobrowseIO: null,
        setSession: (session) => set({ session }),
        setInitialized: (initialized) => set({ initialized }),
        setCobrowseIO: (cobrowseIO) => set({ cobrowseIO }),
    }),
);

export const useSessionStore = create(
    (set, get) => ({
        sdk: null,
        logoutPromise: null,
        descope: null,
        initDescope: (descope) => {
            if (!get().descope) set({ descope });
        },
        isLoggingOut: false,
        logout: async ({ redirect = true } = {}) => {
            const state = get();
            if (state.logoutPromise) return;
            const logoutPromise = state.descope.logout();
            console.log({ logoutPromise });
            set({ logoutPromise, isLoggingOut: true });
            await logoutPromise;
            guardianQueryClient.removeQueries(['getCurrentUser']);
            if (redirect) navigate('/');
            set({ logoutPromise: null, isLoggingOut: false });
        },
        sessionResolverPromise: null,
        isResolving: true,
        manageSessions: async ({ sessionToken }) => {
            const state = get();
            if (state.sessionResolverPromise) return;
            const sessionResolverPromise = (async () => {
                const qs = queryString.parse(window.location.search);
                const loginOnLanding = qs.t;
                if (!loginOnLanding) return;
                const isInviteLink = loginOnLanding && !window.location.search.includes('flow');
                if (loginOnLanding) {
                    if (sessionToken) {
                        await state.logout({ redirect: false });
                    }
                    if (isInviteLink) {
                        await state.descope.magicLink.verify(qs.t);
                        delete qs.t;
                        navigate(`${window.location.pathname}${queryString.stringify(qs)}`);
                    }
                }
            })();
            if (!state.sessionResolverPromise) set({ isResolving: true, sessionResolverPromise });
            await sessionResolverPromise;
            set({ isResolving: false });
        },
        isTransitioning: () => {
            const { isResolving, isLoggingOut } = get();
            return isResolving || isLoggingOut;
        },
    }),
);

export const distributionState = create((set, get) => ({
    data: [],
    loading: false,
    hasErrors: false,
    newAssetType: '',
    setNewAssetType: (newAssetType) => set(() => ({ newAssetType })),
    newAssetSubType: '',
    setNewAssetSubType: (newAssetSubType) => set(() => ({ newAssetSubType })),
    preciousMetalsChildType: '', // for example, if newAssetType is 'Precious MEtals', newAssetSubType is 'State Issued Coins', preciousMetalsChildType is 'Platinum'
    setPreciousMetalsChildType: (preciousMetalsChildType) => set(() => ({ preciousMetalsChildType })),
    showModal: false,
    setShowModal: (showModal) => set(() => ({ showModal })),
    recordType: '',
    setRecordType: (recordType) => set(() => ({ recordType })),
    currentStep: 1,
    setCurrentStep: (step) => set(() => ({ currentStep: step })),
    assets: [],
    setAssets: (assets) => set(() => ({ assets })),
    chosenAsset: {},
    setChosenAsset: (chosenAsset) => set(() => ({ chosenAsset })),
    selectedAssetId: '',
    setSelectedAssetId: (selectedAssetId) => set(() => ({ selectedAssetId })),
    distributionFlowStage1: '',
    setDistributionFlowStage1: (distributionFlowStage1) => set(() => ({ distributionFlowStage1 })),
    transaction: {},
    setTransaction: (transaction) => set(() => ({ transaction })),
    hardshipReason: '',
    setHardshipReason: (hardshipReason) => set(() => ({ hardshipReason })),
    hardshipSignature: '',
    setHardshipSignature: (hardshipSignature) => set(() => ({ hardshipSignature })),
    description: '',
    setDescription: (description) => set(() => ({ description })),
    distributionDocumentId: null,
    setDistributionDocumentId: (distributionDocumentId) => set(() => ({ distributionDocumentId })),

    fetch: async () => {
        set(() => ({ loading: true }));
        try {
            // TODO: API Endpoint Default GET "/transactionNoRecord"
            // TODO Jira bug report: MVP01-321
            throw new Error('API Endpoint Default GET \'/transactionNoRecord\' not implemented');
        } catch (err) {
            set(() => ({
                hasErrors: true,
                loading: false,
            }));
            console.error('From useTransactionStore in appState', err);
        }
    },

    deferralPreTax: {
        enabled: false,
        name: 'Deferral Pre-Tax',
        percent: 0,
        amount: 0,
    },
    setDeferralPreTax: (deferralPreTax) => set(
        produce((draft) => {
            console.log('draft: ', draft.deferralPreTax);
            console.log('incoming from state: ', deferralPreTax);
            draft.deferralPreTax = deferralPreTax;
            console.log('new deferralPreTaxValues: ', deferralPreTax);
        }),
    ),
    deferralRoth: {
        enabled: false,
        name: 'Deferral Roth',
        percent: 0,
        amount: 0,
    },
    setDeferralRoth: (deferralRoth) => set(
        produce((draft) => {
            console.log('draft: ', draft.deferralRoth);
            console.log('incoming from state: ', deferralRoth);
            draft.deferralRoth = deferralRoth;
            console.log('new deferralRothValues: ', deferralRoth);
        }),
    ),
    profitSharing: {
        enabled: false,
        name: 'Profit Sharing',
        percent: 0,
        amount: 0,
    },
    setProfitSharing: (profitSharing) => set(
        produce((draft) => {
            console.log('draft: ', draft.profitSharing);
            console.log('incoming from state: ', profitSharing);
            draft.profitSharing = profitSharing;
            console.log('new profitSharingValues: ', profitSharing);
        }),
    ),
    rollOver: {
        enabled: false,
        name: 'Roll Overs',
        percent: 0,
        amount: 0,
    },
    setRollOver: (rollOver) => set(
        produce((draft) => {
            console.log('draft: ', draft.rollOver);
            console.log('incoming from state: ', rollOver);
            draft.rollOver = rollOver;
            console.log('new ROlloverValues: ', rollOver);
        }),
    ),
    afterTax: {
        enabled: false,
        name: 'After Tax',
        percent: 0,
        amount: 0,
    },
    setAfterTax: (afterTax) => set(
        produce((draft) => {
            console.log('afterTax: ', draft.afterTax);
            console.log('incoming from state: ', afterTax);
            draft.afterTax = afterTax;
            console.log('new ROlloverValues: ', afterTax);
        }),
    ),
    employerMatch: {
        enabled: false,
        name: 'Employer Match',
        percent: 0,
        amount: 0,
    },
    setEmployerMatch: (employerMatch) => set(
        produce((draft) => {
            console.log('draft: ', draft.employerMatch);
            console.log('incoming from state: ', employerMatch);
            draft.employerMatch = employerMatch;
            console.log('new ROlloverValues: ', employerMatch);
        }),
    ),
    onClose: () => set(() => ({
        assetFlowStage1: 'sendingPayment',
        assetPurchasePrice: 0,
        assetTypeLabel: '',
        chosenAsset: {},
        currentStep: 1,
        // data: [],
        hasErrors: false,
        loading: false,
        newAssetSubType: '',
        newAssetType: '',
        preciousMetalsChildType: '', // for example, if newAssetType is 'Precious MEtals', newAssetSubType is 'State Issued Coins'
        recordType: '',
        selectedAssetId: '',
        distributionDocumentId: null,
        showModal: false,
        deferralPreTax: {
            enabled: false,
            name: 'Deferral Pre-Tax',
            percent: 0,
            amount: 0,
        },
        deferralRoth: {
            enabled: false,
            name: 'Deferral Roth',
            percent: 0,
            amount: 0,
        },
        profitSharing: {
            enabled: false,
            name: 'Profit Sharing',
            percent: 0,
            amount: 0,
        },
        rollOver: {
            enabled: false,
            name: 'Roll Overs',
            percent: 0,
            amount: 0,
        },
        afterTax: {
            enabled: false,
            name: 'After Tax',
            percent: 0,
            amount: 0,
        },
        employerMatch: {
            enabled: false,
            name: 'Employer Match',
            percent: 0,
            amount: 0,
        },
    })),
    resetData: () => set(() => ({
        assetFlowStage1: 'sendingPayment',
        assetPurchasePrice: 0,
        assetTypeLabel: '',
        hardship_signature: '',
        chosenAsset: {},
        // currentStep: 1, shouldnt set curretn step to 1 on reset
        // data: [], shouldnt reset data (transactions) on reset only on full close
        hasErrors: false,
        loading: false,
        newAssetSubType: '',
        newAssetType: '',
        preciousMetalsChildType: '', // for example, if newAssetType is 'Precious MEtals', newAssetSubType is 'State Issued Coins'
        recordType: '',
        selectedAssetId: '',
        // showModal: false, shouldn't reset show modal on reset
        deferralPreTax: {
            enabled: false,
            name: 'Deferral Pre-Tax',
            percent: 0,
            amount: 0,
        },
        deferralRoth: {
            enabled: false,
            name: 'Deferral Roth',
            percent: 0,
            amount: 0,
        },
        profitSharing: {
            enabled: false,
            name: 'Profit Sharing',
            percent: 0,
            amount: 0,
        },
        rollOver: {
            enabled: false,
            name: 'Roll Overs',
            percent: 0,
            amount: 0,
        },
        afterTax: {
            enabled: false,
            name: 'After Tax',
            percent: 0,
            amount: 0,
        },
        employerMatch: {
            enabled: false,
            name: 'Employer Match',
            percent: 0,
            amount: 0,
        },
    })),
}));
